import React, { useState, useEffect, useContext } from 'react'
import { columnDefs2Options, filterUndefinedLabels, setVisibility } from './lib/column-selector-lib'
import { limitTable2MaxColumns } from '../table/lib/table-lib'
import { CCol, CFormCheck } from '@coreui/react-pro'
import { AuthContext } from 'src/contexts/authContext'

export default function ColumnSelector({
  columnDefs = [],
  showAllColumns,
  defaultColumns,
  setColumns,
  identifier,
}) {
  const [options, setOptions] = useState([])
  const authContext = useContext(AuthContext)

  useEffect(() => {
    setOptions(columnDefs2Options(columnDefs))
  }, [columnDefs])

  useEffect(() => {
    filterUndefinedLabels(options).map((item, index) => {
      if (defaultColumns) {
        columnDefs = setVisibility(columnDefs, item.value.field, true)
        columnDefs = limitTable2MaxColumns(columnDefs, 10)
        columnDefsTransformation(columnDefs)
      } else if (showAllColumns) {
        columnDefs = setVisibility(columnDefs, item.value.field, true)
        columnDefsTransformation(columnDefs)
      } else {
        columnDefs = setVisibility(columnDefs, item.value.field, false)
      }
      setColumns([...columnDefs])
    })
  }, [showAllColumns, defaultColumns])

  const defaultChecked = (item) => {
    if (item.value.hide === undefined) {
      return true
    } else {
      return !item.value.hide
    }
  }

  const columnDefsTransformation = (columnDefs) => {
    let x = cloneDeep(columnDefs)

    for (let i = 0; i < x.length; i++) {
      if (x[i].cellRenderer) {
        x[i].cellRenderer = x[i].cellRenderer.name
      }
      if (x[i].cellRendererParams) {
        x[i].cellRendererParams = x[i].cellRendererParams.name
      }
      if (x[i].valueGetter) {
        x[i].valueGetter = x[i].valueGetter.name
      }
    }
    localStorage.setItem(`${authContext.role}-${identifier}-columns`, JSON.stringify(x))
    setColumns([...columnDefs])
  }

  const cloneDeep = (obj) => {
    if (obj === null || typeof obj !== 'object') {
      return obj // Return primitive values and null as is
    }

    if (Array.isArray(obj)) {
      const clonedArray = []
      for (let i = 0; i < obj.length; i++) {
        clonedArray[i] = cloneDeep(obj[i])
      }
      return clonedArray
    }

    if (typeof obj === 'object') {
      const clonedObject = {}
      for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
          clonedObject[key] = cloneDeep(obj[key])
        }
      }
      return clonedObject
    }
  }

  return (
    <>
      {filterUndefinedLabels(options).map((item, index) => {
        return (
          <CCol className="col-xs-6 col-md-4" key={index}>
            <CFormCheck
              checked={defaultChecked(item)}
              label={item.label}
              onChange={(v) => {
                columnDefs = setVisibility(columnDefs, item.value.field, v.target.checked)
                columnDefsTransformation(columnDefs)
              }}
            />
          </CCol>
        )
      })}
    </>
  )
}
