import React, { useState, useContext } from 'react'

import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CRow,
  CModalFooter,
  CContainer,
  CCol,
  CFormCheck,
} from '@coreui/react-pro'
import { useTranslation } from 'react-i18next'
import ColumnSelector from 'src/components/columnselector/ColumnSelector'
import { AuthContext } from 'src/contexts/authContext'

export default function TableSettingsModal(props) {
  const authContext = useContext(AuthContext)
  const columnDefs = JSON.parse(
    localStorage.getItem(`${authContext.role}-${props.identifier}-columns`),
  )
  const active = columnDefs?.every((item) => item.hide === false)

  // if active defaultColumns will be set to false & showAllColumns will be true
  const [defaultColumns, setDefaultColumns] = useState(!active)
  const [showAllColumns, setShowAllColumns] = useState(active)
  const { t } = useTranslation()

  return (
    <CModal size="lg" {...props}>
      <CModalHeader>
        <CModalTitle>{'Show Columns'}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CContainer>
              <CRow>
                <ColumnSelector
                  columnDefs={props.columns}
                  setColumns={props.setColumns}
                  showAllColumns={showAllColumns}
                  defaultColumns={defaultColumns}
                  setShowAllColumns={setShowAllColumns}
                  setDefaultColumns={setDefaultColumns}
                  identifier={props.identifier}
                />
              </CRow>
            </CContainer>
          </CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CContainer>
          <CRow>
            <CCol className="col-md-auto">
              <div className="show-all-columns">
                <CFormCheck
                  className="mb-0"
                  label={t('Backoffice.modal.button.label.showall')}
                  checked={showAllColumns}
                  onChange={() => {
                    setDefaultColumns(false)
                    setShowAllColumns(true)
                  }}
                />
              </div>
            </CCol>
            <CCol className="col-md-auto">
              <div className="show-default-columns">
                <CFormCheck
                  className="mb-0"
                  label={t('Backoffice.modal.button.label.showdefault')}
                  checked={defaultColumns}
                  onChange={() => {
                    setDefaultColumns(true)
                    setShowAllColumns(false)
                  }}
                />
              </div>
            </CCol>
          </CRow>
        </CContainer>
      </CModalFooter>
    </CModal>
  )
}
