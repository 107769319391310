import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CFormInput } from '@coreui/react-pro'
import { ROLE } from 'src/contexts/authContext'
import { useFormikContext } from 'formik'
import { SearchableSelect } from './SearchableSelect'

export const FormSelectBranchRole = ({
  partners,
  partner,
  options,
  id,
  name,
  index,
  readOnly,
  partnerRole,
}) => {
  const [parentBranch, setParentBranch] = useState()
  const [childBranches, setChildBranches] = useState([])
  const { t } = useTranslation()
  const { setFieldValue } = useFormikContext()
  const modifiedOptions = [{ label: '' }, ...options.filter((option) => option.parent_id === null)]
  const selectedPartner = partners.find((option) => option.id === parentBranch?.id)
  const [searchValue, setSearchValue] = useState(selectedPartner?.name ?? '')

  const getDisplayRole = (partnerRole, readOnly) => {
    if (partnerRole?.role && readOnly) {
      return t(`Global.roles.${partnerRole.role}`)
    }

    if ((partnerRole?.role && !readOnly) || !partnerRole.role) {
      return t(`Global.roles.${ROLE.BUILDING_CONTRACTOR_ADMIN}`)
    }
  }

  useEffect(() => {
    const loadEffect = async () => {
      const parent = parentBranch?.id || options[0].value
      const res = options.filter((item) => item.parent_id === parent)

      const partnersOptions = res.map((partner) => {
        return { label: partner.label, value: partner.value, type: partner.type }
      })
      setChildBranches(partnersOptions)
    }
    loadEffect()
    setSearchValue(selectedPartner?.name ?? '')
  }, [parentBranch])

  useEffect(() => {
    if (!partner?.parent_id) {
      setParentBranch(partner)
    } else {
      setParentBranch(partners.find((e) => e.id === partner.parent_id))
    }
  }, [partner])

  return (
    <>
      {partners.length > 0 && (
        <div className="my-3">
          <SearchableSelect
            placeholder={t('Backoffice.Wizard.label.partner')}
            name={`partnerId-${index}`}
            id={`partnerId-${index}`}
            options={modifiedOptions.filter((option) =>
              option.label.toLowerCase().includes(searchValue.toLowerCase()),
            )}
            disabled={readOnly}
            value={searchValue}
            className="my-2 form-control-backoffice"
            onChange={(e) => {
              setFieldValue(id, '')
              setSearchValue(e.target.value)
              const _id = modifiedOptions.find((option) => option.label === e.target.value)
              return setParentBranch(partners.find((partner) => partner.id === _id?.value))
            }}
          />
        </div>
      )}
      <SearchableSelect
        placeholder={t('Backoffice.users.table.columns.branch')}
        name={name}
        id={id}
        disabled={readOnly}
        className="my-2 form-control-backoffice"
        options={[
          parentBranch
            ? {
                value: parentBranch.id,
                label: parentBranch.parent_id ? parentBranch.name : parentBranch.name + ' (HQ)',
              }
            : {
                value: options[0].value,
                label: options[0].parent_id ? options[0].label : options[0].label + ' (HQ)',
              },
          ...childBranches,
        ]}
      />
      <CFormInput
        placeholder={t('Backoffice.Wizard.AddUser.Select.Role.PlaceHolder')}
        type="text"
        id={`partnerRoles.${index}.role`}
        name={`partnerRoles.${index}.role`}
        disabled={true}
        value={getDisplayRole(partnerRole, readOnly)}
      />
    </>
  )
}
