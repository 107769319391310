import { FieldArray, useFormikContext } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormAddAnotherButton } from './FormAddAnotherButton'
import { FormDeleteFieldButton } from './FormDeleteFieldButton'
import { SearchableSelect } from './SearchableSelect'

export default function FormSelectMultiplePartners({
  partnerOptions,
  readOnly,
  allBranches,
  setInitialvaluesChanged,
}) {
  const { t } = useTranslation()
  partnerOptions = partnerOptions?.map((partner) => {
    return { label: partner.name, value: partner.id }
  })
  const {
    values: { partners, branches },
    setFieldValue,
  } = useFormikContext()
  let partner_count = 0

  const getBranchOptions = (partnerId) => {
    let selectedPartner = partnerOptions.filter((partner) => partner.value == partnerId)[0]
    let branches = []
    if (!selectedPartner) {
      let parent_id = allBranches.find((branch) => branch.id == partnerId).parent_id
      selectedPartner = partnerOptions.find((partner) => partner.value == parent_id)
      branches = allBranches.filter((branch) => branch.parent_id === parent_id)
    } else {
      branches = allBranches.filter((branch) => branch.parent_id === partnerId)
    }
    branches = branches.map((branch) => {
      return { label: branch?.name, value: branch?.id }
    })
    branches.unshift({ label: selectedPartner.label + ' (HQ)', value: selectedPartner.value })
    return branches
  }

  return (
    <>
      <FieldArray
        name={'partners'}
        render={({ push, remove }) => (
          <>
            {!!partnerOptions.length &&
              partners.map((partner, index) => {
                const options = partnerOptions
                const hasPartnerOptions = options.map((pr) => pr.value).includes(partner)
                partner_count =
                  partner === ''
                    ? partner_count + 1
                    : hasPartnerOptions
                    ? partner_count + 1
                    : partner_count
                return (
                  <>
                    {((!readOnly && partner === '') || hasPartnerOptions) && (
                      <div key={index} className="w-2 h-2 position-relative">
                        <p className="spartan-font">
                          {partners.length === 1
                            ? t('Backoffice.branches.table.columns.partner')
                            : `${t(
                                'Backoffice.branches.table.columns.partner',
                              )} (${partner_count})`}
                        </p>
                        <SearchableSelect
                          className="my-2 form-control-backoffice"
                          placeholder={t('Backoffice.Wizard.label.partner')}
                          name={`partners.${index}`}
                          id={`partners.${index}`}
                          options={options}
                          disabled={readOnly}
                        />
                        {partner && getBranchOptions(partner).length > 0 && (
                          <SearchableSelect
                            className="my-2 form-control-backoffice"
                            placeholder={t('Backoffice.branches.table.columns.branch')}
                            name={`branches.${index}`}
                            id={`branches.${index}`}
                            options={getBranchOptions(partner)}
                            disabled={readOnly}
                          />
                        )}
                        {!readOnly && partners.length > 1 && (
                          <FormDeleteFieldButton
                            index={index}
                            handleClick={() => {
                              remove(index)
                              const updatedBranches = [...branches]
                              updatedBranches.splice(index, 1)
                              setFieldValue('branches', updatedBranches)
                              setInitialvaluesChanged(true)
                            }}
                          />
                        )}
                      </div>
                    )}
                  </>
                )
              })}

            {!readOnly && <FormAddAnotherButton push={push} values={''} />}
          </>
        )}
      />
    </>
  )
}
