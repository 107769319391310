import { FieldArray, useFormikContext } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext, ROLE } from 'src/contexts/authContext'
import { FormTextInput } from '../FormTextInput'
import { FormDeleteFieldButton } from './FormDeleteFieldButton'
import { FormSelect } from './FormSelect'
import { FormSelectPartnerRole } from './FormSelectPartnerRole'

export const partnerRolesValues = {
  buyLimit: '',
  partnerId: '',
  currency: 'CHF',
  role: '',
}

export default function FormSelectMultiplePartnerRoles({ partners, readOnly }) {
  const { t } = useTranslation()
  const {
    values: { partnerRoles },
  } = useFormikContext()

  const authContext = useContext(AuthContext)
  const [partnerType, setPartnerType] = useState('building_contractor')

  const partnersOptions = partners.map((partner) => {
    return {
      label: partner.name,
      value: partner.id,
      type: partner.type,
      parent_id: partner.parent_id,
    }
  })

  return (
    <>
      <FieldArray
        name="partnerRoles"
        render={({ push, remove }) => (
          <>
            {partnerRoles.map((partnerRole, index) => {
              return (
                <div
                  key={index}
                  className={`${partnerRoles.length - 1 !== index ? 'mb-5' : ''} position-relative`}
                >
                  {!readOnly && partnerRoles.length > 1 && (
                    <FormDeleteFieldButton handleClick={remove} index={index} />
                  )}
                  <FormSelectPartnerRole
                    partners={partners}
                    partnerRole={partnerRole}
                    options={partnersOptions}
                    id={`partnerRoles.${index}.partnerId`}
                    name={`partnerRoles.${index}.partnerId`}
                    index={index}
                    readOnly={readOnly}
                    setSelectedPartnerType={setPartnerType}
                  />

                  {authContext.role !== ROLE.FOREMAN && (
                    <>
                      {(authContext.role === ROLE.BUILDING_CONTRACTOR_ADMIN ||
                        authContext.role === ROLE.LEAD_BUYER ||
                        authContext.role === ROLE.BUYER ||
                        authContext.role === ROLE.CUBOTOO_ADMIN) &&
                        partnerType !== 'manufacturer' &&
                        partnerType !== '' && (
                          <FormTextInput
                            placeholder={t('Backoffice.Wizard.AddUser.Label.OrderLimit')}
                            type="number"
                            id={`partnerRoles.${index}.buyLimit`}
                            name={`partnerRoles.${index}.buyLimit`}
                            disabled={readOnly}
                          />
                        )}

                      <FormSelect
                        placeholder={t('Backoffice.Wizard.AddUser.Select.currency.placeHolder')}
                        disabled
                        id={`partnerRoles.${index}.currency`}
                        name={`partnerRoles.${index}.currency`}
                        className="my-2 form-control-backoffice"
                        options={[
                          {
                            value: 'CHF',
                            label: 'CHF',
                          },
                        ]}
                      />
                    </>
                  )}
                </div>
              )
            })}
            {/* {!readOnly && <FormAddAnotherButton push={push} values={partnerRolesValues} />} */}
          </>
        )}
      />
    </>
  )
}
