import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { useEffect, useRef } from 'react'
import { SearchableSelect } from './SearchableSelect'

export const FormSelectPartners = ({ ...props }) => {
  const isFirstRender = useRef(true)
  const { values, setFieldValue } = useFormikContext()
  const { t } = useTranslation()
  const partners = props.partners?.map((partner) => {
    return { label: partner.name, value: partner.id }
  })

  useEffect(() => {
    const selectedPartner = props?.partners?.find((item) => item.id === values.partner)
    if (props.initialPartner?.current !== selectedPartner?.id) {
      setFieldValue('HGCPartnerId', selectedPartner?.partner_config?.hgc_partner_id)
      setFieldValue('HGCPartnerCreditId', selectedPartner?.partner_config?.hgc_partner_credit_id)
      setFieldValue('primaryContactEmail', selectedPartner?.partner_config?.contact_email)
    }
    props.initialPartner.current = selectedPartner?.id
  }, [values.partner])

  return (
    <SearchableSelect
      placeholder={t('Backoffice.Wizard.label.partner')}
      name="partner"
      disabled={props.readOnly}
      id="partner"
      options={partners}
      {...props}
    />
  )
}
