import { TABTYPE } from 'src/hooks/useBackofficeParams'
import moment from 'moment'
import { MAX_YEARS } from '../../projectmaterialview/components/lib/projectmaterialview/filterSection-lib'

export const usersColumnDefs = () => {
  return [
    { field: 'id', hide: true, headerName: 'Backoffice.users.table.columns.userId' },
    {
      field: 'last_name',
      headerName: 'Backoffice.users.table.columns.lastName',
      pinned: 'left',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      cellRenderer: 'OverviewRowRenderer',
    },
    {
      field: 'first_name',
      headerName: 'Backoffice.users.table.columns.firstName',
    },
    {
      field: 'email',
      headerName: 'Backoffice.users.table.columns.email',
    },
    {
      field: 'phone',
      headerName: 'Backoffice.users.table.columns.phone',
    },
    {
      field: 'language',
      valueGetter: 'languageGetter',
      headerName: 'Backoffice.users.table.columns.language',
    },
    {
      field: 'date_of_birth',
      valueGetter: 'dobGetter',
      headerName: 'Backoffice.users.table.columns.dateOfBirth',
    },
    {
      field: 'active',
      headerName: 'Backoffice.users.table.columns.status',
      cellRenderer: 'BadgeCellRenderer',
      suppressSizeToFit: true,
      maxWidth: 240,
      minWidth: 240,
    },
    {
      field: 'partner_roles',
      headerName: 'Backoffice.users.table.columns.role',
      valueGetter: 'rolesGetter',
      cellRenderer: 'BadgeCellRenderer',
      suppressSizeToFit: true,
      maxWidth: 240,
      minWidth: 240,
    },
    {
      field: 'Action',
      headerName: 'Backoffice.users.table.columns.action',
      cellRenderer: 'TrashCellRenderer',
      suppressSizeToFit: true,
      minWidth: 260,
    },
  ]
}

export const branchesColumnDefs = () => {
  return [
    {
      field: 'parent_name',
      headerName: 'Backoffice.branches.table.columns.partner',
    },
    {
      field: 'name',
      headerName: 'Backoffice.branches.table.columns.branch',
      pinned: 'left',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      cellRenderer: 'OverviewRowRenderer',
    },
    {
      field: 'countryName',
      headerName: 'Backoffice.branches.table.columns.branchCountry',
    },
    {
      field: 'legal_entity.vat_num',
      headerName: 'Backoffice.branches.table.columns.vat',
    },
    {
      field: 'partner_config.hgc_partner_id',
      headerName: 'Backoffice.branches.table.columns.hgcPartnerId',
    },
    {
      field: 'partner_config.hgc_partner_credit_id',
      headerName: 'Backoffice.branches.table.columns.hgcPartnerCreditNo',
    },
    {
      field: 'domain',
      headerName: 'Backoffice.branches.table.columns.domain',
    },
    {
      field: 'address.countryName',
      headerName: 'Backoffice.branches.table.columns.address.countryName',
    },
    {
      field: 'address.postal_code',
      headerName: 'Backoffice.branches.table.columns.postalCode',
    },
    {
      field: 'address.city',
      headerName: 'Backoffice.branches.table.columns.city',
    },
    {
      field: 'address.street',
      headerName: 'Backoffice.branches.table.columns.street',
    },
    {
      field: 'address.house_nr',
      headerName: 'Backoffice.branches.table.columns.houseNr',
    },
    {
      field: 'address.lat',
      headerName: 'Backoffice.branches.table.columns.lat',
    },
    {
      field: 'address.lon',
      headerName: 'Backoffice.branches.table.columns.lon',
    },
    {
      field: 'status',
      headerName: 'Backoffice.users.table.columns.status',
      cellRenderer: 'BadgeCellRenderer',
      suppressSizeToFit: true,
      maxWidth: 240,
      minWidth: 240,
    },
    {
      headerName: 'Backoffice.partners.table.columns.action',
      cellRenderer: 'TrashCellRenderer',
      suppressSizeToFit: true,
      minWidth: 260,
    },
  ]
}

export const projectsColumnDefs = () => {
  return [
    {
      field: 'name',
      headerName: 'Backoffice.projects.table.columns.projectName',
      pinned: 'left',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      cellRenderer: 'OverviewRowRenderer',
    },
    {
      headerName: 'Backoffice.projects.table.columns.addresses',
      field: 'addresses',
      cellRenderer: 'NestedRowsRenderer',
      wrapText: true,
      autoHeight: true,
      cellRendererParams: 'cellRendererParamsAddresses',
    },
    {
      headerName: 'Backoffice.projects.table.columns.contacts',
      field: 'filtered_contacts',
      cellRenderer: 'NestedRowsRenderer',
      cellRendererParams: 'cellRendererParamsContacts',
    },
    {
      headerName: 'Backoffice.projects.table.columns.partners',
      field: 'partner_ids',
      cellRenderer: 'MultipleRowsRenderer',
      cellRendererParams: 'cellRendererParamsPartnerIDs',
    },
    {
      field: 'status',
      headerName: 'Backoffice.users.table.columns.status',
      cellRenderer: 'BadgeCellRenderer',
      suppressSizeToFit: true,
      maxWidth: 240,
      minWidth: 240,
    },
    {
      headerName: 'Backoffice.partners.table.columns.action',
      cellRenderer: 'TrashCellRenderer',
      suppressSizeToFit: true,
      minWidth: 260,
    },
  ]
}

export const partnersManufacturersColumnDefs = () => {
  return [
    {
      field: 'name',
      headerName: 'Backoffice.partners.table.columns.name',
      pinned: 'left',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      cellRenderer: 'OverviewRowRenderer',
    },
    {
      field: 'legal_entity.vat_num',
      headerName: 'Backoffice.partners.table.columns.vatNum',
    },
    {
      field: 'partner_config.hgc_partner_id',
      headerName: 'Backoffice.partners.table.columns.hgcPartnerId',
    },
    {
      field: 'partner_config.hgc_partner_credit_id',
      headerName: 'Backoffice.partners.table.columns.hgcPartnerCreditNo',
    },
    {
      field: 'domain',
      headerName: 'Backoffice.partners.table.columns.domain',
    },
    {
      field: 'prefix',
      headerName: 'Backoffice.partners.table.columns.prefix',
    },
    {
      field: 'countryName',
      headerName: 'Backoffice.partners.table.columns.country',
    },
    {
      field: 'legal_entity.purpose',
      headerName: 'Backoffice.partners.table.columns.purpose',
    },
    {
      field: 'partner_config.next_day_delivery',
      headerName: 'Backoffice.partners.table.columns.nextDayDelivery',
      cellRenderer: 'CheckBoxCellRenderer',
    },
    {
      field: 'partner_config.video_call',
      headerName: 'Backoffice.partners.table.columns.videoCall',
      cellRenderer: 'CheckBoxCellRenderer',
    },
    {
      field: 'partner_config.product_availability_display',
      headerName: 'Backoffice.partners.table.columns.productAvailabilityDisplay',
      cellRenderer: 'BadgeCellRenderer',
      suppressSizeToFit: true,
      maxWidth: 240,
      minWidth: 240,
    },
    {
      field: 'partner_config.settlement_fee_type',
      headerName: 'Backoffice.partners.table.columns.settlementFeeType',
      cellRenderer: 'BadgeCellRenderer',
      suppressSizeToFit: true,
      maxWidth: 240,
      minWidth: 240,
    },
    {
      field: 'partner_config.settlement_fee_percentage',
      headerName: 'Backoffice.partners.table.columns.settlementFeePercentage',
    },
    {
      field: 'partner_config.percentage',
      headerName: 'Backoffice.partners.table.columns.percentage',
    },
    {
      field: 'partner_config.payment_terms',
      headerName: 'Backoffice.partners.table.columns.paymentTerms',
    },
    {
      field: 'partner_config.hide_prices',
      headerName: 'Backoffice.partners.table.columns.hidePrices',
      cellRenderer: 'CheckBoxCellRenderer',
    },
    {
      field: 'languages',
      headerName: 'Backoffice.partners.table.columns.languages',
    },
    {
      field: 'address.countryName',
      headerName: 'Backoffice.partners.table.columns.address.countryName',
    },
    {
      field: 'address.postal_code',
      headerName: 'Backoffice.partners.table.columns.postalCode',
    },
    {
      field: 'address.city',
      headerName: 'Backoffice.partners.table.columns.city',
    },
    {
      field: 'address.street',
      headerName: 'Backoffice.partners.table.columns.street',
    },
    {
      field: 'address.house_nr',
      headerName: 'Backoffice.partners.table.columns.houseNr',
    },
    {
      field: 'address.lat',
      headerName: 'Backoffice.partners.table.columns.lat',
    },
    {
      field: 'address.lon',
      headerName: 'Backoffice.partners.table.columns.lon',
    },
    {
      field: 'users',
      headerName: 'Backoffice.partners.table.columns.users',
    },
    {
      field: 'partner_config.franco_limit',
      headerName: 'Backoffice.partners.table.columns.francoLimit',
    },
    {
      field: 'partner_config.lead_time',
      headerName: 'Backoffice.partners.table.columns.leadTime',
    },
    {
      field: 'partner_config.invoice_email',
      headerName: 'Backoffice.partners.table.columns.invoiceEmail',
    },
    {
      field: 'partner_config.credit_email',
      headerName: 'Backoffice.partners.table.columns.creditEmail',
    },
    {
      field: 'partner_config.receive_credit_emails',
      headerName: 'Backoffice.partners.table.columns.receiveCreditEmail',
    },
    {
      field: 'partner_config.receive_accumulate_fee_invoices',
      headerName: 'Backoffice.partners.table.columns.receiveAccumulateFeeEmail',
    },
    {
      field: 'status',
      headerName: 'Backoffice.users.table.columns.status',
      cellRenderer: 'BadgeCellRenderer',
      suppressSizeToFit: true,
      maxWidth: 240,
      minWidth: 240,
    },
    {
      headerName: 'Backoffice.partners.table.columns.action',
      cellRenderer: 'TrashCellRenderer',
      suppressSizeToFit: true,
      minWidth: 260,
    },
  ]
}

export const partnersBuildingContractorsColumnDefs = () => {
  return [
    {
      field: 'name',
      headerName: 'Backoffice.partners.table.columns.name',
      pinned: 'left',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      cellRenderer: 'OverviewRowRenderer',
    },
    {
      field: 'legal_entity.vat_num',
      headerName: 'Backoffice.partners.table.columns.vatNum',
    },
    {
      field: 'partner_config.hgc_partner_id',
      headerName: 'Backoffice.partners.table.columns.hgcPartnerId',
    },
    {
      field: 'partner_config.hgc_partner_credit_id',
      headerName: 'Backoffice.partners.table.columns.hgcPartnerCreditNo',
    },
    {
      field: 'domain',
      headerName: 'Backoffice.partners.table.columns.domain',
    },
    {
      field: 'countryName',
      headerName: 'Backoffice.partners.table.columns.country',
    },
    {
      field: 'legal_entity.purpose',
      headerName: 'Backoffice.partners.table.columns.purpose',
    },
    {
      field: 'partner_config.contact_email',
      headerName: 'Backoffice.partners.table.columns.contactEmail',
    },
    {
      field: 'partner_config.video_call',
      headerName: 'Backoffice.partners.table.columns.videoCall',
      cellRenderer: 'CheckBoxCellRenderer',
    },
    {
      field: 'partner_config.payment_terms',
      headerName: 'Backoffice.partners.table.columns.paymentTerms',
      cellRenderer: 'CheckBoxCellRenderer',
    },
    {
      field: 'address.countryName',
      headerName: 'Backoffice.partners.table.columns.address.countryName',
    },
    {
      field: 'address.postal_code',
      headerName: 'Backoffice.partners.table.columns.postalCode',
    },
    {
      field: 'address.city',
      headerName: 'Backoffice.partners.table.columns.city',
    },
    {
      field: 'address.street',
      headerName: 'Backoffice.partners.table.columns.street',
    },
    {
      field: 'address.house_nr',
      headerName: 'Backoffice.partners.table.columns.houseNr',
    },
    {
      field: 'address.lat',
      headerName: 'Backoffice.partners.table.columns.lat',
    },
    {
      field: 'address.lon',
      headerName: 'Backoffice.partners.table.columns.lon',
    },
    {
      field: 'branches',
      headerName: 'Backoffice.partners.table.columns.branches',
    },
    {
      field: 'projects',
      headerName: 'Backoffice.partners.table.columns.projects',
    },
    {
      field: 'users',
      headerName: 'Backoffice.partners.table.columns.users',
    },
    {
      field: 'partner_config.invoice_email',
      headerName: 'Backoffice.partners.table.columns.invoiceEmail',
    },
    {
      field: 'partner_config.credit_email',
      headerName: 'Backoffice.partners.table.columns.creditEmail',
    },
    {
      field: 'status',
      headerName: 'Backoffice.users.table.columns.status',
      cellRenderer: 'BadgeCellRenderer',
      suppressSizeToFit: true,
      maxWidth: 240,
      minWidth: 240,
    },
    {
      headerName: 'Backoffice.partners.table.columns.action',
      cellRenderer: 'TrashCellRenderer',
      suppressSizeToFit: true,
      minWidth: 260,
    },
  ]
}

export const mfErpIntegrationColumnDefs = () => {
  return [
    {
      field: 'name',
      headerKey: 'Backoffice.partners.table.columns.name',
      pinned: 'left',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      cellRenderer: 'OverviewRowRenderer',
    },

    {
      field: 'partner_id',
      headerName: 'Backoffice.MfErpIntegration.table.columns.partner_id',
    },
    {
      field: 'erp_type',
      headerName: 'Backoffice.MfErpIntegration.table.columns.erpType',
    },
    {
      field: 'protocol',
      headerName: 'Backoffice.MfErpIntegration.table.columns.protocol',
    },
    {
      field: 'status',
      headerName: 'Backoffice.users.table.columns.status',
      cellRenderer: 'BadgeCellRenderer',
      suppressSizeToFit: true,
      maxWidth: 240,
      minWidth: 240,
    },
    {
      field: 'canFinalizeWithoutERPConfirmation',
      headerName: 'Backoffice.MfErpIntegration.table.columns.finalizeWithoutConfirmation',
      cellRenderer: 'CheckBoxCellRenderer',
    },
  ]
}

const filterUsers = (data, value, key) => {
  return data.filter((item) => {
    return item[key] === value
  })
}

export const getUsersByType = (data, selectedTab) => {
  switch (selectedTab) {
    case TABTYPE.MANUFACTURERS:
      return filterUsers(data, 'MF', 'type')

    case TABTYPE.BUILDINGCONTRACTORS:
      return filterUsers(data, 'BC', 'type')

    default:
      return []
  }
}

export const userAction = (data, value, action) => {
  let user = filterUsers(data, value, 'id')[0]
  action(user)
}

export const allPartnerFields = [
  'type',
  'name',
  'country',
  'vatNum',
  'HGCPartnerId',
  'HGCPartnerCreditId',
  'prefix',
  'purpose',
  'domain',
  'status',
  'addressCountry',
  'street',
  'houseNumber',
  'city',
  'postalCode',
  'contactEmail',
  'nextDayDeliveryTrue',
  'nextDayDeliveryFalse',
  'paymentTerms',
  'productAvailabilityDisplay',
  'settlementFeeType',
  'settlementFeePercentage',
  'percentage',
  'hidePricesTrue',
  'hidePricesFalse',
  'languagesGerman',
  'languagesFrench',
  'languagesEnglish',
  'languagesItalian',
  'francoLimit',
  'leadTime',
  'invoice_email',
  'credit_email',
  'receive_credit_emails',
  'receive_accumulate_fee_invoices',
]

export const allBranchFields = [
  'partner',
  'name',
  'branchCountry',
  'vat',
  'primaryContactEmail',
  'HGCPartnerId',
  'HGCPartnerCreditId',
  'purpose',
  'domain',
  'status',
  'country',
  'postalCode',
  'city',
  'street',
  'houseNumber',
  'invoice_email',
  'credit_email',
]

export function transformDate(date) {
  let momentDate = moment(date)
  let hoursOffset = getHoursOffset(date)
  let transformedDate
  if (hoursOffset < 0) {
    transformedDate = momentDate.add(moment.duration(Math.abs(hoursOffset), 'hours'))
  } else {
    transformedDate = momentDate.subtract(moment.duration(Math.abs(hoursOffset), 'hours'))
  }
  return transformedDate
}

function getHoursOffset(date) {
  const offset = date.getTimezoneOffset() / 60
  return offset
}

export function getMinDate(date) {
  if (!date) return new Date().setHours(0, 0, 0, 0)

  let minDate = new Date(date)
  minDate.setHours(0, 0, 0, 0)
  return minDate
}

export const addYears = (data) => {
  const dates = []
  data.forEach((holiday) => {
    dates.push(holiday)

    if (holiday.is_recurring) {
      for (let i = 1; i <= MAX_YEARS; i++) {
        const date = new Date(holiday.holiday_date)
        date.setFullYear(date.getFullYear() + i)
        dates.push({
          id: holiday.id,
          holiday_name: holiday.holiday_name,
          holiday_date: date.toISOString(),
          is_recurring: holiday.is_recurring,
        })
      }
    }
  })
  return dates
}
